import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  FormLayout,
  TextField,
  Checkbox,
  Layout,
  BlockStack,
  Text,
  Box,
  InlineStack,
  SkeletonDisplayText,
  SkeletonBodyText,
  Select,
  Button
} from "@shopify/polaris";
import { isEqual, keys, forEach, pick } from "lodash";
import AnnouncementBar from "./AnnouncementBar";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import FontPicker from "../../components/FontPicker/FontPicker";
import "./AnnouncementSettings.css";
import { DefaultTheme, Themes } from "./AnnouncementBarThemes";

const defaultSettings = {
  ContentDays: "Days",
  ContentHours: "Hrs",
  ContentMinutes: "Mins",
  ContentSeconds: "Secs",
  MarginElements: 4,
  Font: "",
  FontFlipNumber: "",
  SizeText: 14,
  SizeFlipNumber: 18,
  SizeFlipLabelText: null,
  ColorText: "#FFD600",
  ColorTextRight: "#FFD600",
  ColorBackground: "#262626",
  ColorButton: "#FFD600",
  ColorButtonText: "#262626",
  ImageBackground: "",
  ColorFlipBottom: "#100C08",
  ColorFlipTop: "#100C08",
  ColorFlipNumber: "#FFD600",
  ColorFlipLabelText: "#FFD600",
  SizeFlipCornerRadius: 5,
  TextBeforeTimer: "Sale ends in",
  TextAfterTimer: "15 %",
  ShowCloseButton: true,
  ShowFadeinAnimation: true,
  ShowRightTextMobile: false,
  ShowButtonMobile: true,
  ShowOneLineMobile: true,
  DisableFlipEffectMobile: false,
  Position: 1,
  ...DefaultTheme
};

export const mergeDefaultSettings = (props) => {
  const result = { ...defaultSettings };
  forEach(keys(defaultSettings), (key) => {
    if (props[key] != null) result[key] = props[key];
  });
  return result;
};

const AnnouncementSettings = ({
  settings = { ...defaultSettings },
  onSettingsChange = (settings) => { }
}) => {
  const [state, setState] = useState(mergeDefaultSettings(settings));
  const [selectedTheme, setSelectedTheme] = useState("Default");

  // Update state when settings prop changes
  useEffect(() => {
    setState(mergeDefaultSettings(settings));
  }, [settings]);

  const handleChange = useCallback(
    (property) => (value) => {
      const newState = {
        ...state,
        [property]: value,
      };
      setState(newState);
      onSettingsChange(newState);
    },
    [state, onSettingsChange]
  );

  const handleThemeChange = useCallback((value) => {
    setSelectedTheme(value);
    const newState = {
      ...state,
      ...(value === "Default" ? DefaultTheme : Themes[value])
    };
    setState(newState);
    onSettingsChange(newState);
  }, [state, onSettingsChange]);

  const validateColor = useCallback((color) => {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color) || !color ? "" : "Please input valid color format (#ABC or #AABBCC).";
  }, []);

  const resetToDefaultFromList = useCallback((variableNames) => {
    setState((prevState) => {
      const resetState = { ...prevState };
      variableNames.forEach((varName) => {
        if (varName in defaultSettings) {
          resetState[varName] = defaultSettings[varName];
        }
      });
      return resetState;
    });
  }, []);

  const tabs = [
    { id: "bar-layout", title: "Bar layout", content: "Bar layout", panelID: "bar-layout-content" },
    { id: "timer-layout", content: "Countdown timer", panelID: "timer-layout-content" },
    { id: "button-layout", content: "Button layout", panelID: "button-layout-content" },
    { id: "effects-layout", content: "Effects", panelID: "effects-layout-content" },
    { id: "mobile-layout", content: "Mobile", panelID: "mobile-layout-content" },
  ];

  const themeOptions = [
    { label: "Default", value: "Default" },
    ...Object.keys(Themes).map((theme) => ({
      label: theme.split(/(?=[A-Z0-9])/).join(' '),
      value: theme,
    })),
  ];

  const renderThemeSelector = () => (
    <Card>
      <BlockStack gap="200">
        <Text as="h3" variant="headingSm">Theme Selector</Text>
        <Select
          label="Choose a theme"
          options={themeOptions}
          onChange={handleThemeChange}
          value={selectedTheme}
        />
      </BlockStack>
    </Card>
  );

  const renderBarLayoutForm = () => (
    <BlockStack gap="200">
      <FormLayout.Group>
        <Select
          label="Position"
          options={[
            { label: "Top of page", value: 0 },
            { label: "Top of page - sticky", value: 1 },
            { label: "Bottom of page - sticky", value: 2 },
          ]}
          value={state.Position}
          onChange={(value) => handleChange("Position")(Number(value))}
        />
        <TextField
          type="number"
          label="Spacing"
          helpText="Set spacing between elements."
          onChange={handleChange("MarginElements")}
          value={String(state.MarginElements)}
          suffix="px"
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <FontPicker
          label={"Text font"}
          onChange={handleChange("Font")}
          value={state.Font}
        />
        <TextField
          type="number"
          placeholder="Inherited"
          label="Text size"
          value={String(state.SizeText)}
          onChange={handleChange("SizeText")}
          suffix="pt"
        />
      </FormLayout.Group>
      <FormLayout.Group condensed>
        <TextField
          type="string"
          placeholder="Inherited"
          label="Text color (left side)"
          value={state.ColorText}
          error={validateColor(state.ColorText)}
          onChange={handleChange("ColorText")}
          connectedLeft={
            <ColorPicker
              color={state.ColorText}
              onUpdateColor={handleChange("ColorText")}
            />
          }
        />
        <TextField
          type="string"
          placeholder="Inherited"
          label="Text color (right side)"
          value={state.ColorTextRight}
          error={validateColor(state.ColorTextRight)}
          onChange={handleChange("ColorTextRight")}
          connectedLeft={
            <ColorPicker
              color={state.ColorTextRight}
              onUpdateColor={handleChange("ColorTextRight")}
            />
          }
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          type="string"
          label="Background color"
          value={state.ColorBackground}
          error={validateColor(state.ColorBackground)}
          onChange={handleChange("ColorBackground")}
          connectedLeft={
            <ColorPicker
              color={state.ColorBackground}
              onUpdateColor={handleChange("ColorBackground")}
            />
          }
        />
      </FormLayout.Group>
    </BlockStack>
  );

  const renderTimerLayoutForm = () => (
    <BlockStack gap="200">
      <FormLayout.Group condensed>
        {["Days", "Hours", "Minutes", "Seconds"].map((unit) => (
          <TextField
            key={unit}
            type="string"
            label={`${unit} label`}
            onChange={handleChange(`Content${unit}`)}
            value={state[`Content${unit}`]}
          />
        ))}
        <TextField
          type="string"
          label="Label color"
          helpText="Set the color of the time unit labels."
          onChange={handleChange("ColorFlipLabelText")}
          value={state.ColorFlipLabelText}
          error={validateColor(state.ColorFlipLabelText)}
          connectedLeft={
            <ColorPicker
              color={state.ColorFlipLabelText}
              onUpdateColor={handleChange("ColorFlipLabelText")}
            />
          }
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <FontPicker
          label={"Number font"}
          onChange={handleChange("FontFlipNumber")}
          value={state.FontFlipNumber}
        />
        <TextField
          type="number"
          label="Number font size"
          value={String(state.SizeFlipNumber)}
          onChange={handleChange("SizeFlipNumber")}
          suffix="px"
        />
        <TextField
          type="string"
          label="Number color"
          onChange={handleChange("ColorFlipNumber")}
          value={state.ColorFlipNumber}
          error={validateColor(state.ColorFlipNumber)}
          connectedLeft={
            <ColorPicker
              color={state.ColorFlipNumber}
              onUpdateColor={handleChange("ColorFlipNumber")}
            />
          }
        />
      </FormLayout.Group>
      <FormLayout.Group condensed helpText="Set the top and bottom colors of the flip card.">
        <TextField
          type="string"
          label="Card color (top)"
          onChange={handleChange("ColorFlipTop")}
          value={state.ColorFlipTop}
          error={validateColor(state.ColorFlipTop)}
          connectedLeft={
            <ColorPicker
              color={state.ColorFlipTop}
              onUpdateColor={handleChange("ColorFlipTop")}
            />
          }
        />
        <TextField
          type="string"
          label="Card color (bottom)"
          onChange={handleChange("ColorFlipBottom")}
          value={state.ColorFlipBottom}
          error={validateColor(state.ColorFlipBottom)}
          connectedLeft={
            <ColorPicker
              color={state.ColorFlipBottom}
              onUpdateColor={handleChange("ColorFlipBottom")}
            />
          }
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          type="number"
          label="Card corner radius"
          value={String(state.SizeFlipCornerRadius)}
          onChange={handleChange("SizeFlipCornerRadius")}
          helpText="Adjust the roundness of the card corners."
          suffix="px"
        />
      </FormLayout.Group>
    </BlockStack>
  );

  const renderButtonLayoutForm = () => (
    <FormLayout.Group helpText="Specify desired button and button text color.">
      <TextField
        type="string"
        label="Button color"
        onChange={handleChange("ColorButton")}
        value={state.ColorButton}
        error={validateColor(state.ColorButton)}
        connectedLeft={
          <ColorPicker
            color={state.ColorButton}
            onUpdateColor={handleChange("ColorButton")}
          />
        }
      />
      <TextField
        type="string"
        label="Button text color"
        onChange={handleChange("ColorButtonText")}
        value={state.ColorButtonText}
        error={validateColor(state.ColorButtonText)}
        connectedLeft={
          <ColorPicker
            color={state.ColorButtonText}
            onUpdateColor={handleChange("ColorButtonText")}
          />
        }
      />
    </FormLayout.Group>
  );

  const renderEffectsLayoutForm = () => (
    <FormLayout.Group>
      <Checkbox
        label="Show fade-in animation"
        onChange={handleChange("ShowFadeinAnimation")}
        checked={state.ShowFadeinAnimation}
      />
      <Checkbox
        label="Show close button"
        onChange={handleChange("ShowCloseButton")}
        checked={state.ShowCloseButton}
      />
    </FormLayout.Group>
  );

  const renderMobileLayoutForm = () => (
    <FormLayout.Group>
      <Checkbox
        label="Show call to action / subscribe button on mobile devices"
        onChange={handleChange("ShowButtonMobile")}
        checked={state.ShowButtonMobile}
      />
      <Checkbox
        label="Show secondary text (after countdown) on mobile devices"
        onChange={handleChange("ShowRightTextMobile")}
        checked={state.ShowRightTextMobile}
      />
      <Checkbox
        label="Disable countdown timer flip effect on Apple mobile devices"
        onChange={handleChange("DisableFlipEffectMobile")}
        checked={state.DisableFlipEffectMobile}
      />
      <Checkbox
        label="Show all elements on one line (instead of wrapping secondary text and button onto next line)"
        onChange={handleChange("ShowOneLineMobile")}
        checked={state.ShowOneLineMobile}
      />
    </FormLayout.Group>
  );

  const formCards = [
    {
      render: renderBarLayoutForm,
      stateVariables: ['Font', 'SizeText', 'ColorText', 'ColorTextRight', 'Position', 'ColorBackground', 'MarginElements']
    },
    {
      render: renderTimerLayoutForm,
      stateVariables: ['FontFlipNumber', 'SizeFlipNumber', 'ColorFlipNumber', 'ColorFlipTop', 'ColorFlipBottom', 'SizeFlipCornerRadius', 'ColorFlipLabelText', 'ContentDays', 'ContentHours', 'ContentMinutes', 'ContentSeconds']
    },
    {
      render: renderButtonLayoutForm,
      stateVariables: ['ColorButton', 'ColorButtonText']
    },
    {
      render: renderEffectsLayoutForm,
      stateVariables: ['ShowFadeinAnimation', 'ShowCloseButton']
    },
    {
      render: renderMobileLayoutForm,
      stateVariables: ['ShowButtonMobile', 'ShowRightTextMobile', 'DisableFlipEffectMobile', 'ShowOneLineMobile']
    }
  ];

  return (
    <Layout>
      <Layout.Section variant="oneThird">
        <BlockStack gap="400">
          {renderThemeSelector()}
          {formCards.map(({ render: renderChild, stateVariables }, index) => (
            <Card key={index}>
              <BlockStack gap="200">
                <InlineStack gap="200" align="space-between">
                  <Text as="h3" variant="headingSm">{tabs[index].content}</Text>
                  <Button 
                    variant="plain"
                    onClick={() => resetToDefaultFromList(stateVariables)}
                    disabled={isEqual(
                      pick(defaultSettings, stateVariables),
                      pick(state, stateVariables)
                    )}
                  >
                    Reset to default
                  </Button>
                </InlineStack>
                <FormLayout>
                  {renderChild()}
                </FormLayout>
              </BlockStack>
            </Card>
          ))}
        </BlockStack>
      </Layout.Section>
      <Layout.Section variant="oneHalf">
        <div className="sticky-container">
          <BlockStack gap="200">
            <Card>
              <BlockStack gap="400">
                <Text as="h3" variant="headingSm">Preview</Text>
                {
                  (state.Position === 0 || state.Position === 1) &&
                    <AnnouncementBar {...state} />
                }
                <InlineStack gap="500" align="start" wrap={false}>
                  <Box width="50%" background="bg-surface-secondary" borderRadius="200" height="400px" />
                  <Box width="50%">
                    <SkeletonDisplayText size="large" />
                    <Box paddingBlockStart="400">
                      <SkeletonBodyText lines={3} />
                    </Box>
                    <Box paddingBlockStart="400">
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <Box paddingBlockStart="400">
                      <InlineStack gap="300">
                        <Box background="bg-surface-active" borderRadius="200" paddingInline="400" paddingBlock="300" width="100%">
                          <SkeletonBodyText lines={1} />
                        </Box>
                        <Box background="bg-surface-active" borderRadius="200" paddingInline="400" paddingBlock="300" width="50%">
                          <SkeletonBodyText lines={1} />
                        </Box>
                      </InlineStack>
                    </Box>
                  </Box>
                </InlineStack>
                {
                  state.Position === 2 &&
                    <AnnouncementBar {...state} />
                }
              </BlockStack>
            </Card>
          </BlockStack>
        </div>
        <div style={{ height: '100vh' }}>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default AnnouncementSettings;