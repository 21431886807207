import React, { Component, createRef } from "react";
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";
import { getCssVarName, formatCssValue } from "disco-script-tag/src/utils/css-utils";
import "disco-script-tag/src/notification-bar/css/style.css";

export default class AnnouncementBar extends Component {
  static defaultProps = {
    ContentDays: "Days",
    ContentHours: "Hours",
    ContentMinutes: "Mins",
    ContentSeconds: "Secs",
    MarginElements: 4,
    Font: "Chewy",
    FontFlipNumber: "Chewy",
    SizeText: 14,
    SizeFlipNumber: 18,
    SizeFlipLabelText: "0.6em",
    ColorText: "#000",
    ColorTextRight: "#000",
    ColorBackground: "#fde073",
    ColorButton: "#000",
    ColorButtonText: "#fde073",
    ImageBackground: "",
    ColorFlipBottom: "#393939",
    ColorFlipTop: "#222",
    ColorFlipNumber: "#fff",
    ColorFlipLabelText: "#000",
    SizeFlipCornerRadius: 5,
    ShowCloseButton: false,
    ShowFadeinAnimation: true,
    Position: 1,
    TextBeforeTimer: "Big sale ends in",
    TextAfterTimer: "15 % OFF",
    ButtonText: "Shop now",
    EmailPlaceholder: "Get notified!",
    ShowTimer: true,
    IsBeforeStart: false
  };

  node = createRef();

  componentDidMount() {
    this.updateCSSVariables();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateCSSVariables();
    }
  }

  updateCSSVariables() {
    let CSSVariables = { ...this.props };

    Object.entries(CSSVariables).forEach(([prop, value]) => {
      this.handleProp(prop, value);
    });
  }

  handleProp(prop, value) {
    switch (prop) {
      case "FontFlipNumber":
      case "Font":
        this.node.current.style.setProperty(
          getCssVarName(prop, "pe-ab"),
          formatCssValue(prop, value)
        );
        if (prop === "Font")
          this.node.current.style.setProperty(
            getCssVarName(prop, "pe-ab") + "-url",
            `"//fonts.googleapis.com/css?family=${value}:300,400,600,700&amp;lang=en"`
          );
        break;

      default:
        this.node.current.style.setProperty(
          getCssVarName(prop, "pe-ab"),
          formatCssValue(prop, value)
        );
    }
  }

  render() {
    const countdownTimer = (
      <CountdownTimer
        SizeFlipNumber={this.props.SizeFlipNumber}
        DivId="pe-notification-bar-flip-clock"
        FlipClockClassName="pe-notification-bar-flip-clock"
        {...this.props}
      />
    );

    const textBefore = <div className="pe-notification-bar-text-left">{this.props.TextBeforeTimer}</div>;
    const textAfter = <div className="pe-notification-bar-text-right">{this.props.TextAfterTimer}</div>;
    const closeButtonMarkup = <div className="close-btn" style={{position: "inherit"}}>✖</div>;
    const buttonMarkup = this.props.ButtonText ? (
      <div>
        <button className="pe-cta-btn">{this.props.ButtonText}</button>
      </div>
    ) : "";
    const subscribeMarkup = (
      <>
        <div>
          <input type="email" placeholder={this.props.EmailPlaceholder}></input>
        <div>
        </div>
          <button className="pe-cta-btn">{this.props.ButtonText}</button>
        </div>
      </>
    );

    return (
      <div ref={this.node} id="pe-announcement-bar">
        <div id="pe-announcement-bar-items">
          <div className="pe-announcement-bar-item">
            {this.props.TextBeforeTimer ? textBefore : ""}
            {this.props.ShowTimer ? countdownTimer : ""}
            {this.props.TextAfterTimer ? textAfter : ""}
            {this.props.IsBeforeStart !== true ? buttonMarkup : subscribeMarkup}
            {this.props.ShowCloseButton === true ? closeButtonMarkup : ""}
          </div>
        </div>
      </div>
    );
  }
}
