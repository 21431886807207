import React from 'react';
import {
  Button,
  BlockStack,
  Text
} from "@shopify/polaris";
import {
  ChatMajor
} from "@shopify/polaris-icons";
import DismissableBanner from "./DismissableBanner";

const HelpBanner = () => {
    return (
        <DismissableBanner propName={"needHelpDashboard"} expiryHrs={168} icon={ChatMajor}>
            <BlockStack gap={100}>
                <Text variant="bodyMd" as="span" tone="bold">Need help?</Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                    <Button variant="plain" onClick={() => window.Beacon('open')}><span class="live-chat-link">Contact us via in-app live chat</span></Button> or email at <a href="mailto:support@productessentials.app">support@productessentials.app</a>. We respond to live chat on weekdays from 9 AM to 5 PM CET. For other times, we aim to reply within 24 hours.
                </Text>
            </BlockStack>
        </DismissableBanner>
    );
};

export default HelpBanner;