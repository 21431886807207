import React, { Component, createRef } from "react";
import { createCountdownTimerBoxElement } from "disco-script-tag/src/countdown-timer/countdown-timer";
import { getCssVarName, formatCssValue } from "disco-script-tag/src/utils/css-utils";
import "disco-script-tag/src/countdown-timer/css/style.css";

export default class CountdownTimer extends Component {
  static defaultProps = {
    ContentHeading: '"Hurry up! Sale ends in"',
    ContentDays: '"Days"',
    ContentHours: '"Hours"',
    ContentMinutes: '"Minutes"',
    ContentSeconds: '"Seconds"',
    MarginTop: 15,
    MarginBottom: 0,
    MarginLeft: 0,
    MarginRight: 0,
    BoxMaxWidth: null,
    BoxMaxHeight: null,
    SizeHeading: "",
    SizeCornerRadius: 8,
    SizeFlipCornerRadius: 5,
    SizeFlipNumber: 36,
    ColorText: null,
    ColorBackground: "#EEE",
    ColorFlipBottom: "#393939",
    ColorFlipTop: "#222",
    ColorFlipNumberTop: "#ccc",
    ColorFlipNumberBottom: "#fff",
    ColorFlipSeparator: "#000",
    ShouldShowFlipSeparator: false,
    DivId: "ct_pe_disco",
    FlipClockClassName: "disco-flip-clock"
  };

  node = createRef();

  separatorVars = {
    SizeFlipSeparator: "1px",
    FlipCardHeight: "0.72em",
    FlipCardMargin: "-0.72em"
  }

  componentDidMount() {
    this.updateCSSVariables();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateCSSVariables();
    }
  }

  updateCSSVariables() {
    if (this.props.ShouldShowFlipSeparator) {
      this.separatorVars.SizeFlipSeparator = "1px";
      this.separatorVars.FlipCardHeight = "0.72em";
      this.separatorVars.FlipCardMargin = "-0.72em";
    } else {
      this.separatorVars.SizeFlipSeparator = "0px";
      this.separatorVars.FlipCardHeight = "0.70em";
      this.separatorVars.FlipCardMargin = "-0.70em";
    }

    let CSSVariables = {...this.props, ...this.separatorVars};

    Object.entries(CSSVariables).forEach(([prop, value]) => {
      if (prop !== "ShouldShowFlipSeparator" && prop !== "DivId" && prop !== "FlipClockClassName")
        this.node.current.style.setProperty(
          getCssVarName(prop, "pe-ct"),
          formatCssValue(prop, value)
        );
    });
  }

  render() {
    const deadline = new Date(
      Date.parse(new Date()) +
        24 * 60 * 60 * 1000 +
        3 * 60 * 1000 +
        60 * 60 * 2000 +
        40000
    );
    const [timerBox, ] = createCountdownTimerBoxElement(this.props, deadline, "pe-ct-editor", null, this.props.FlipClockClassName);
    return (
      <div ref={this.node}>
        <div
          id={this.props.DivId}
          className={this.props.FlipClockClassName}
          dangerouslySetInnerHTML={{ __html: timerBox.innerHTML }}
        />
      </div>
    );
  }
}
