import React, { useState, useEffect } from 'react';
import { getWithExpiry, setWithExpiry } from '../../utils/LocalStorageExpiry';
import { Banner } from '@shopify/polaris';

const DismissableBanner = ({ propName, expiryHrs = 12, ...props }) => {
  // Create a unique key for localStorage based on the propName
  const localStorageKey = `dismissed_${propName}`;

  // State to manage the visibility of the banner
  const [isDismissed, setIsDismissed] = useState(
    // Check localStorage for the dismissed state
    JSON.parse(getWithExpiry(localStorageKey)) || false
  );

  // Update localStorage when the banner is dismissed
  const handleDismiss = () => {
    setIsDismissed(true);
    setWithExpiry(localStorageKey, 'true', 1000 * 60 * 60 * expiryHrs);
    if (props.onDismiss) props.onDismiss(); // Call the onDismiss prop if it exists
  };

  useEffect(() => {
    // This effect runs once on mount and never again (empty dependency array)
    // Check if the dismissed state is already in localStorage
    const dismissed = getWithExpiry(localStorageKey);
    if (dismissed) {
      setIsDismissed(JSON.parse(dismissed));
    }
  }, [localStorageKey]);

  // Don't render the banner if it has been dismissed
  if (isDismissed) {
    return null;
  }
  
  return (
    <>
      {!isDismissed &&
        <Banner {...props} onDismiss={handleDismiss}>
          {props.children}
        </Banner>
      }
    </>
  );
};

export default DismissableBanner;