import React from "react";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { useEffect } from "react";
import { SubscriptionPlan } from "./SubscriptionPlan";
import axios from "axios";
import Config from "../../utils/Config";
import { redirectIframe } from "../../components/ExitIFrame/ExitIFrame";

const performSubscriptionOp = async (authQueryString, app, host) => {
    const installUrl = `${Config.apiGateway.URL}/authentication/install/${Config.appName}${authQueryString}`;
    const installStatus = await axios.get(installUrl);

    if (installStatus.status === 200) {
        if (installStatus.data.Plan === SubscriptionPlan.Starter) {
            // Refresh app with new plan.
            //redirectLocal("/", app);
            redirectIframe(`https://${host}`, app);
        } else {
            // Redirect to charge activation page.
            redirectIframe(installStatus.data.RedirectUrl, app);
        }
    }
};

export function UpdatePermissionsRoute({ host, authQueryString }) {
    const app = useAppBridge();

    useEffect(() => {
        performSubscriptionOp(`?updatePermissions=true&${authQueryString}`, app, host);
    }, [app, authQueryString, host]);

    return <Loading />;
}

export function UpgradeRoute({ host, authQueryString }) {
    const app = useAppBridge();

    useEffect(() => {
        performSubscriptionOp(`?upgrade=true&${authQueryString}`, app, host);
    }, [app, authQueryString, host]);

    return <Loading />;
}

export function DowngradeRoute({ host, authQueryString }) {
    const app = useAppBridge();

    useEffect(() => {
        performSubscriptionOp(`?downgrade=true&${authQueryString}`, app, host);
    }, [app, authQueryString, host]);

    return <Loading />;
}